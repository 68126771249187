import { css } from '@emotion/react';
import { Player } from '@gamepark/croa/player';
import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { CroaAvatar } from '../material/player/Avatar';
import { FrogAnimation } from '../material/frog/FrogAnimation';
import { queenMiniRatio, rankingRatio } from '../utils/Styles';
import { GamePoints } from '@gamepark/react-client';

type RankProps = {
    rank: number;
    player?: Player;
    reduced?: boolean;
} & HTMLAttributes<HTMLDivElement>

const Rank: React.FC<RankProps> = ({rank, player, reduced, ...props}) => {
    const playerInfo = useSelector((state: any) => state.players.find((p: any) => p.id === player?.color));
    return (
        <div { ...props } css={ rankContainer }>
            <div css={ [getFrog(rank), reduced && hideFrog ] }>
                { player && <FrogAnimation visible={ true } duration={ rank === 1? 0.6: 1 } delay={ rank * 0.7 } frog={ player.femaleFrogs.find(frog => frog.isQueen)! } loop={ true } animation={ rank === 1? 'jumping_front': 'blinking' } css={ css`position: relative;` }  /> }
            </div>
            <div css={ [getRank(rank), reduced && rankDisabled] }>
                { player && <CroaAvatar css={ [avatarCss, reduced && avatarReducedCss] } player={ player} playerInfo={ playerInfo } /> }
                { player && <GamePoints playerId={player.color} css={ [gamePointsStyle, reduced && hideGamePoints] }/>}
            </div>
        </div>
    );
}

const gamePointsStyle = css`
    height: 1.2em;
    font-size: 3em;
    width: 100%;
    display: flex;
    justify-content: center;
    color: black;
    transition: opacity 0.2s linear, height 0.2s linear 0.2s;
`;

const hideGamePoints = css`
    opacity: 0;
    height: 0;
`

const avatarCss = css`
    height: 6em;
    width: 6em;
    filter: drop-shadow(0 0.2em 0.2em black);
    margin-bottom: 0.4em;
`;

const avatarReducedCss = css`
    position: absolute;
    bottom: 0.4em
`

const rankContainer = css`
    display: flex;
    justify-content: center;
`;

const getRank = (rank: number) => css`
    position: absolute;
    bottom: 0;
    height: ${30 + (5 * (4 - rank))}%;
    display: flex;
    align-items: center;
    flex-direction: column; 
    padding-bottom: 0.5em;
    z-index: ${4 - rank};
    justify-content: flex-end;
    width: 100%;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    box-shadow: 0 0.3em 0.5em black;
    background-color: ${getRankColor(rank)};
`;

const rankDisabled = css`
    height: 1em;
`

const frogWidth = 65
const frogHeight = frogWidth / queenMiniRatio * rankingRatio;
// height * ratio / ranking

const getFrog = (rank: number) => css`
    z-index: ${5 - rank};
    position: absolute;
    height: ${ frogHeight }%;
    width: ${ frogWidth }%;
    bottom: ${(30 + (5 * (4 - rank)) - 5) + (rank === 1? 38: 0)}%;
`

const hideFrog = css`
  opacity: 0;
`;

const getRankColor = (rank: number) => {
    switch(rank) {
        case 1:
            return 'gold';
        case 2:
            return 'silver';
        case 3:
            return '#cd7f32';
        default:
            return 'gray';
    }
}

export {
    Rank
}