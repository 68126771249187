import { Player, PlayerColor } from '@gamepark/croa/player';
import { FC, HTMLAttributes } from 'react';
import { Images } from '../Resources';
import { Avatar } from '@gamepark/react-client';
import { SpeechBubbleDirection } from '@gamepark/react-client/dist/Avatar';
import { Picture } from '@gamepark/react-components';
import { css } from '@emotion/react';

type CroaAvatarProps = {
    player: Player
    playerInfo?: any,
    color?: PlayerColor
    speechBubbleDirection?: SpeechBubbleDirection
} & Omit<HTMLAttributes<HTMLElement>, 'color'>

const CroaAvatar: FC<CroaAvatarProps> = ({player, playerInfo, color, speechBubbleDirection, ...props}) => {
    if (playerInfo?.avatar) {
        return <Avatar playerId={player.color} speechBubbleProps={{direction: speechBubbleDirection}} {...props}/>
    } else {
        return <Picture alt={'Player board'} src={playerDefaultImages.get(color || player.color)} {...props} css={autoHeight}/>
    }
}

const playerDefaultImages = new Map<PlayerColor, any>();
playerDefaultImages.set(PlayerColor.Blue, Images.DefaultBlueAvatar);
playerDefaultImages.set(PlayerColor.Green, Images.DefaultGreenAvatar);
playerDefaultImages.set(PlayerColor.Red, Images.DefaultRedAvatar);
playerDefaultImages.set(PlayerColor.Pink, Images.DefaultPinkAvatar);

const autoHeight = css`
    height: auto !important;
`

export {
    CroaAvatar
}