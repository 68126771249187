import { css } from '@emotion/react';
import { Player, PlayerColor } from '@gamepark/croa/player';
import React from 'react';
import { playerBoardMaleTokensHeight, playerColors, playerColorsDark } from '../../utils/Styles';
import { getPlayerName } from '@gamepark/croa/CroaOptions';
import { useTranslation } from 'react-i18next';
import { CroaAvatar } from './Avatar';
import { GamePoints, Player as PlayerInfo, PlayerTimer, SpeechBubbleDirection } from '@gamepark/react-client';

type PlayerInfosProps = {
    player: Player
    playerInfo: PlayerInfo
    color?: PlayerColor
    speechBubbleDirection?: SpeechBubbleDirection
}

const PlayerInfos: React.FC<PlayerInfosProps> = ({ player, playerInfo, color, speechBubbleDirection }) => {
    const {t} = useTranslation();
    const realColor = color || player.color;
    
    return (
        <>
        <div css={ avatarContainer(realColor) }>
            <CroaAvatar player={ player } playerInfo={ playerInfo } color={ realColor } speechBubbleDirection={ speechBubbleDirection } css={avatarCss} />
        </div>
        <div css={titleStyle(realColor)}>
            <span css={[nameStyle, playerInfo.quit && quit ]}>{ playerInfo?.name || getPlayerName(player.color, t) }</span>
            <GamePoints playerId={ player.color } css={ gamePointsStyle }/>
            { playerInfo?.time?.playing && <PlayerTimer css={ gamePointsStyle } playerId={ player.color } /> }
        </div>
        </>
    )

}

const gamePointsStyle = css`
    position: absolute;
    right: 2%;
    display: flex;
    align-items: center;
`;

const quit = css`
    text-decoration: line-through;
`;

const avatarContainer = (playerColor: PlayerColor) => css`
    z-index: 2;
    position: absolute;
    height: 31%;
    width: 20%;
    left: -3%;
    border-radius: 4em;
    bottom: ${ 10 + playerBoardMaleTokensHeight }%;
    border: 0.4em solid rgb(${playerColorsDark.get(playerColor)!.rgb.r}, ${playerColorsDark.get(playerColor)!.rgb.g}, ${playerColorsDark.get(playerColor)!.rgb.b});
    background-color: rgb(${playerColorsDark.get(playerColor)!.rgb.r}, ${playerColorsDark.get(playerColor)!.rgb.g}, ${playerColorsDark.get(playerColor)!.rgb.b});
`;

const avatarCss = css`
  position: absolute;
  width: 6.6em;
  height: 6.2em;
  left: -0.3em;
  bottom: -0.2em;
`

const titleStyle = (playerColor: PlayerColor) => css`
    color: white;
    position: absolute;
    width: 93%;
    height: 21%;
    bottom: ${ 12 + playerBoardMaleTokensHeight }%;
    left: 8%;
    right: 3%;
    margin: 0;
    font-size: 2.8em;
    padding-top: 0.1em;
    font-weight: normal;
    display: flex;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    justify-content: space-between;
    border: 0.12em solid rgb(${playerColorsDark.get(playerColor)!.rgb.r}, ${playerColorsDark.get(playerColor)!.rgb.g}, ${playerColorsDark.get(playerColor)!.rgb.b});
    background-color: rgb(${playerColors.get(playerColor)!.rgb.r}, ${playerColors.get(playerColor)!.rgb.g}, ${playerColors.get(playerColor)!.rgb.b});
`;

const nameStyle = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  position:absolute;
  letter-spacing: 0.03em;
  left: 10%;
`;

export {
    PlayerInfos
}