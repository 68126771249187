import BlueMale from './tile/visuals/blue-male.png';
import GreenMale from './tile/visuals/green-male.png';
import PinkMale from './tile/visuals/pink-male.png';
import YellowMale from './tile/visuals/yellow-male.png';
import PurpleMale from './tile/visuals/purple-male.png';
import RedMale from './tile/visuals/red-male.png';
import WaterLily from './tile/visuals/water-lily.png';
import Mosquito from './tile/visuals/mosquito.png';
import Mud from './tile/visuals/mud.png';
import Reed from './tile/visuals/reed.png';
import Pike from './tile/visuals/pike.png';
import Log from './tile/visuals/log.png';
import Shallow from './tile/visuals/shallow.png';
import Deep from './tile/visuals/deep.png';

import BoardBackground from './board/visuals/board.jpg';
import BlueMaleTokenGreen from './token/visuals/green/blue-male.png';
import RedMaleTokenGreen from './token/visuals/green/red-male.png';
import PinkMaleTokenGreen from './token/visuals/green/pink-male.png';
import YellowMaleTokenGreen from './token/visuals/green/yellow-male.png';
import GreenMaleTokenGreen from './token/visuals/green/green-male.png';
import PurpleMaleTokenGreen from './token/visuals/green/purple-male.png';

import BlueMaleTokenRed from './token/visuals/red/blue-male.png';
import RedMaleTokenRed from './token/visuals/red/red-male.png';
import PinkMaleTokenRed from './token/visuals/red/pink-male.png';
import YellowMaleTokenRed from './token/visuals/red/yellow-male.png';
import GreenMaleTokenRed from './token/visuals/red/green-male.png';
import PurpleMaleTokenRed from './token/visuals/red/purple-male.png';

import BlueMaleTokenBlue from './token/visuals/blue/blue-male.png';
import RedMaleTokenBlue from './token/visuals/blue/red-male.png';
import PinkMaleTokenBlue from './token/visuals/blue/pink-male.png';
import YellowMaleTokenBlue from './token/visuals/blue/yellow-male.png';
import GreenMaleTokenBlue from './token/visuals/blue/green-male.png';
import PurpleMaleTokenBlue from './token/visuals/blue/purple-male.png';

import BlueMaleTokenPink from './token/visuals/pink/blue-male.png';
import RedMaleTokenPink from './token/visuals/pink/red-male.png';
import PinkMaleTokenPink from './token/visuals/pink/pink-male.png';
import YellowMaleTokenPink from './token/visuals/pink/yellow-male.png';
import GreenMaleTokenPink from './token/visuals/pink/green-male.png';
import PurpleMaleTokenPink from './token/visuals/pink/purple-male.png';

import BlueQueen from './frog/visuals/blue-queen.png';
import BlueServant from './frog/visuals/blue-servant.png';
import GreenQueen from './frog/visuals/green-queen.png';
import GreenServant from './frog/visuals/green-servant.png';
import PinkQueen from './frog/visuals/pink-queen.png';
import PinkServant from './frog/visuals/pink-servant.png';
import RedQueen from './frog/visuals/red-queen.png';
import RedServant from './frog/visuals/red-servant.png';

import DefaultBlueAvatar from './player/visuals/default-blue-avatar.png';
import DefaultRedAvatar from './player/visuals/default-red-avatar.png';
import DefaultGreenAvatar from './player/visuals/default-green-avatar.png';
import DefaultPinkAvatar from './player/visuals/default-pink-avatar.png';

import BlueQueenBlinking from './frog/animations/blinking/blue-queen.png';
import RedQueenBlinking from './frog/animations/blinking/red-queen.png';
import GreenQueenBlinking from './frog/animations/blinking/green-queen.png';
import PinkQueenBlinking from './frog/animations/blinking/pink-queen.png';

import BlueServantBlinking from './frog/animations/blinking/blue-servant.png';
import RedServantBlinking from './frog/animations/blinking/red-servant.png';
import GreenServantBlinking from './frog/animations/blinking/green-servant.png';
import PinkServantBlinking from './frog/animations/blinking/pink-servant.png';

import BlueQueenJumpingFront from './frog/animations/jumping/blue-queen-front.png';
import RedQueenJumpingFront from './frog/animations/jumping/red-queen-front.png';
import GreenQueenJumpingFront from './frog/animations/jumping/green-queen-front.png';
import PinkQueenJumpingFront from './frog/animations/jumping/pink-queen-front.png';

import BlueServantJumpingFront from './frog/animations/jumping/blue-servant-front.png';
import RedServantJumpingFront from './frog/animations/jumping/red-servant-front.png';
import GreenServantJumpingFront from './frog/animations/jumping/green-servant-front.png';
import PinkServantJumpingFront from './frog/animations/jumping/pink-servant-front.png';

import BlueQueenJumpingBack from './frog/animations/jumping/blue-queen-back.png';
import RedQueenJumpingBack from './frog/animations/jumping/red-queen-back.png';
import GreenQueenJumpingBack from './frog/animations/jumping/green-queen-back.png';
import PinkQueenJumpingBack from './frog/animations/jumping/pink-queen-back.png';

import BlueServantJumpingBack from './frog/animations/jumping/blue-servant-back.png';
import RedServantJumpingBack from './frog/animations/jumping/red-servant-back.png';
import GreenServantJumpingBack from './frog/animations/jumping/green-servant-back.png';
import PinkServantJumpingBack from './frog/animations/jumping/pink-servant-back.png';

import jumpSound from '../sounds/jump.mp3';
import croaSound from '../sounds/croa.mp3';
import mudSound from '../sounds/mud.mp3';
import mosquitoSound from '../sounds/mosquito.mp3';
import eliminationSound from '../sounds/elimination.mp3';
import ambianceSound from '../sounds/ambiance.mp3';
import pikeSound from '../sounds/pike.mp3';
import revealSound from '../sounds/reveal.mp3';

const Images = {
    BlueMale, GreenMale, PinkMale, YellowMale, PurpleMale, RedMale,
    WaterLily, Mosquito, Mud, Reed, Pike, Log, Shallow, Deep,
    BlueMaleTokenGreen, RedMaleTokenGreen, PinkMaleTokenGreen, YellowMaleTokenGreen, GreenMaleTokenGreen, PurpleMaleTokenGreen,
    BlueMaleTokenRed, RedMaleTokenRed, PinkMaleTokenRed, YellowMaleTokenRed, GreenMaleTokenRed, PurpleMaleTokenRed,
    BlueMaleTokenBlue, RedMaleTokenBlue, PinkMaleTokenBlue, YellowMaleTokenBlue, GreenMaleTokenBlue, PurpleMaleTokenBlue,
    BlueMaleTokenPink, RedMaleTokenPink, PinkMaleTokenPink, YellowMaleTokenPink, GreenMaleTokenPink, PurpleMaleTokenPink,
    BlueQueen, BlueServant, GreenQueen, GreenServant, PinkQueen, PinkServant, RedQueen, RedServant,
    DefaultBlueAvatar, DefaultGreenAvatar, DefaultPinkAvatar, DefaultRedAvatar,
    BlueQueenBlinking, RedQueenBlinking, GreenQueenBlinking, PinkQueenBlinking,
    BlueServantBlinking, RedServantBlinking, GreenServantBlinking, PinkServantBlinking,
    BlueQueenJumpingFront, RedQueenJumpingFront ,GreenQueenJumpingFront, PinkQueenJumpingFront,
    BlueServantJumpingFront, RedServantJumpingFront ,GreenServantJumpingFront, PinkServantJumpingFront,
    BlueQueenJumpingBack, RedQueenJumpingBack ,GreenQueenJumpingBack, PinkQueenJumpingBack,
    BlueServantJumpingBack, RedServantJumpingBack ,GreenServantJumpingBack, PinkServantJumpingBack,
    BoardBackground
}


const Sounds = {
    jumpSound,
    croaSound,
    mudSound,
    mosquitoSound,
    eliminationSound,
    ambianceSound,
    pikeSound,
    revealSound
};

export { 
    Images,
    Sounds
}
