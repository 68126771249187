enum MoveType {
  MoveFrog = 1,
  EliminateFrog,
  RevealSlab,
  PlaySlabEffect,
  AcquireServant,
  SkipTurn
}

export {
  MoveType
}